<template>
    <zx-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="公司名称" >
            <el-input v-model.trim="form.company_name" placeholder="请输入公司名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="区域" v-if="$store.getters.usertype=='1'">
            <el-select v-model="form.area" placeholder="请选择" clearable >
                <el-option :label="item.dictLabel" :value="item.dictValue" v-for="item in dictList" :key="item.pk">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="状态" v-if="$store.getters.usertype=='1'">
            <el-select v-model="form.status" placeholder="请选择" clearable >
                <el-option :label="item.dictLabel" :value="item.dictValue" v-for="item in dictStatus" :key="item.pk">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="时间">
            <el-date-picker v-model="form.daterange"
                            clearable
                            style="float: right; padding: 3px 10px"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd">
            </el-date-picker>
        </el-form-item>
    </zx-search-form-inline>
</template>

<script>
    export default {
        props: {
            dictList: {
                default: []
            },
        },
        data() {
            return {
                form: {
                    daterange:[]
                },
                dictStatus: []
            }
        },
        methods: {
            async getdictStatus() {
                try {
                    const resp = await this.$http.get(`/system/dictData/company_status/?page=0&pageSize=1000`)
                    if (!resp.data) {
                        this.dictStatus= []
                    } else {
                        this.dictStatus = resp.data.data
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            doSearch() {
                this.$emit('search', this.form)
            }
        },
        mounted() {
            this.getdictStatus()
        }
    }
</script>

