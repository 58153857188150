const data = {
    table: {
        columns: [
            {title: '应用', field: 'app_name', width: '300'},
            {title: '分类', field: 'app_category', width: ''},
            // {title: '开发者', field: 'developer', width: ''},
            {title: '发布日期', field: 'release_time', width: ''},
            {title: '最后更新', field: 'app_version_time', width: ''},
            {title: '昨日下载量', field: 'yesterdayDownload', width: ''},
            {title: '评分', field: 'current_version_user_rating', width: ''},
            {title: '状态', field: 'online', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
