<template>
    <div class="app-info-card-item">
        <div class=" dd-flex dd-flex-center dd-desc-color">
            {{form.name}}
        </div>
        <div class="app-value dd-flex dd-flex-center dd-align-center dd-base-color font-16 font-500">
            <span>{{form.count}}</span>
        </div>
        <div class="app-desc-value dd-flex dd-flex-center">总数
        </div>
    </div>
</template>

<script>
    export default {
        name: "zx-market-card",
        props :{
            form:{}
        }
    }
</script>


<style lang="scss" scoped>
    .title {
        display: flex;
        margin-left: 20px;
        margin-top: 20px;
        position: relative;

        img {
            border: 1px solid #eee;
            border-radius: 12px;
            border-radius: 24%;
            display: block;
            height: 70px;
            width: 70px;
            margin-right: 15px;
        }

        .title-text {
            display: inline-flex;
            flex-direction: column;
            justify-content: space-around;
        }

        .p-title {
            color: #222;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .app-info-card {
        transition: all .5s;
        width: 100%;
        height: 102px;
        background: #fbfbfb;
        padding: 0 23px;
        position: relative;
    }

    .dd-flex {
        display: flex;
    }

    .app-info-card .app-info-card-item {
        padding-top: 13px;
        flex: 1;
        word-break: break-all;
        overflow: hidden;
        height: 100%;
    }

    app-info-card .app-info-card-item .title {
        width: 100%;
        font-size: 12px;
    }

    .dd-desc-color {
        color: #74757a;
    }

    .dd-flex-center {
        justify-content: center;
    }

    .app-info-card .app-info-card-item .app-value {
        width: 100%;
        height: 40px;
        line-height: 40px;
    }

    .dd-ee-tooltip-wrap {
        width: auto;
        max-width: 100%;
        overflow: hidden;
    }

    .app-info-card .app-info-card-item .app-desc-value {
        width: 100%;
        font-size: 12px;
    }

    .dd-base-color {
        color: #111;
    }

    .dd-align-center {
        align-items: center;
    }

    .dd-flex-center {
        justify-content: center;
    }

    .dd-flex {
        display: flex;
    }

    .font-16 {
        font-size: 16px;
    }

    .font-500 {
        font-family: PingFangSC-Medium, PingFang SC, sans-serif;
        font-weight: 600;
    }

    .dd-pointer {
        cursor: pointer;
    }

    .font-12 {
        font-size: 12px;
        color: #2259e5;
    }

    .img {
        font-size: 18px;
        font-weight: bold;
        padding: 0 10px;
    }
    .head-title {
        position: absolute;
        content: "";
        display: inline-block;
        width: 4px;
        height: 16px;
        top: 33px;
        background-color: #2259e5;
        transform: translateY(-50%);
        border-radius: 2px;
    }
    .info{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 15px 0;
        border-bottom: 1px solid #f5f5f5;
    }
    .screen-list{
        padding-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        img{
            width: auto;
            height: 348px;
            min-width: 196px;
            margin-left: 12px;
        }
    }
    .el-row{
        padding-bottom: 10px;
    }
    .descriptions{
        padding: 10px;
    }
    .tabss  ::v-deep .el-card__body,::v-deep  .el-main{
        padding: 0px 20px;
    }
    ::v-deep .el-tabs__header {
        padding: 0;
        position: relative;
        margin: 0 0 0px;
    }
</style>

