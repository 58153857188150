<template>
    <div class="app-info-card dd-flex">
        <div class="app-info-card-item" v-for="i in [1,2,3,4,5,6,7]" :key="i">
            <div class=" dd-flex dd-flex-center dd-desc-color">
                <div v-if="i==1">APP ID</div>
                <div v-if="i==2">{{form.app_info.rateDetail | fileterValue }}个评论</div>
                <div v-if="i==3">分类榜</div>
                <div v-if="i==4">价格</div>
                <div v-if="i==5">内购</div>
                <div v-if="i==6">开发者</div>
                <div v-if="i==7">最新更新</div>
            </div>
            <div class="app-value dd-flex dd-flex-center dd-align-center dd-base-color font-16 font-500">
                <div v-if="i==1">{{form.app_id}}</div>
                <div v-if="i==2">{{ratingAverage}}</div>
                <div v-if="i==3">{{form.app_info.genre | filterrank}}</div>
                <div v-if="i==4">{{form.app_info.price}}</div>
                <div v-if="i==5">{{form.app_info.purchases_num}}</div>
                <div v-if="i==6"><i class="el-icon-s-custom" /> </div>
                <div v-if="i==7">{{ form.base_info | fliteVertion}}</div>
            </div>
            <div class="app-desc-value dd-flex dd-flex-center">
                <el-link v-if="i==1" :href="form.app_info.appstore_url" class="download-icon font-12 dd-pointer el-popover__reference" target="_blank" type="primary">下载APP</el-link>
                <el-rate v-if="i==2" v-model="ratingAverage"   disabled  text-color="#ff9900"></el-rate>
                <span v-if="i==3">{{ form.base_info | fliteBaseInfo}}</span>
                <span v-if="i==4">应用</span>
                <span v-if="i==5">项</span>
                <span v-if="i==6">{{form.developer}}</span>
                <span v-if="i==7">{{form.app_version_time}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "zx-card-info",
        props :{
            form:{},
        },
        data() {
            return {
                ratingAverage:0
            }
        },
        filters: {
            fileterValue(value) {
                if (!value) return '0';
                var current = value.current
                if (current){
                    return current.ratingCount
                }
                return "0"
            },
            fliteBaseInfo(value){
                value = value.appInfo
                if(!value) return '-';
                for (var i=0;i<value.length;i++){
                    if(value[i].name == "分类")
                        return value[i].value
                }
            },
            fliteVertion(value){
                value = value.appInfo
                if (!value) return '-';
                for (var i=0;i<value.length;i++){
                    if(value[i].name == "版本")
                        return value[i].value
                }
            },
            filterrank(value){
                if (!value) return '-';
                return value.rank
            },
        },
        watch: {
            form: {
                deep: true,
                handler(newValue) {
                    if (!newValue) return '0';
                    var current = newValue.app_info
                    if (current){
                        this.ratingAverage = current.rateDetail.current.ratingAverage
                    }
                }
            }
        },

    }
</script>


<style lang="scss" scoped>
    .title {
        display: flex;
        margin-left: 20px;
        margin-top: 20px;
        position: relative;

        img {
            border: 1px solid #eee;
            border-radius: 12px;
            display: block;
            height: 70px;
            width: 70px;
            margin-right: 15px;
        }

        .title-text {
            display: inline-flex;
            flex-direction: column;
            justify-content: space-around;
        }

        .p-title {
            color: #222;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .app-info-card {
        transition: all .5s;
        width: 100%;
        height: 102px;
        background: #fbfbfb;
        padding: 0 23px;
        position: relative;
    }

    .dd-flex {
        display: flex;
    }

    .app-info-card .app-info-card-item {
        padding-top: 13px;
        flex: 1;
        word-break: break-all;
        overflow: hidden;
        height: 100%;
    }

    app-info-card .app-info-card-item .title {
        width: 100%;
        font-size: 12px;
    }

    .dd-desc-color {
        color: #74757a;
    }

    .dd-flex-center {
        justify-content: center;

    }

    .app-info-card .app-info-card-item .app-value {
        width: 100%;
        height: 40px;
        line-height: 40px;
    }

    .dd-ee-tooltip-wrap {
        width: auto;
        max-width: 100%;
        overflow: hidden;
    }

    .app-info-card .app-info-card-item .app-desc-value {
        width: 100%;
        font-size: 12px;
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .dd-base-color {
        color: #111;
    }

    .dd-align-center {
        align-items: center;
    }

    .dd-flex-center {
        justify-content: center;
    }

    .dd-flex {
        display: flex;
    }

    .font-16 {
        font-size: 16px;
    }

    .font-500 {
        font-family: PingFangSC-Medium, PingFang SC, sans-serif;
        font-weight: 600;
    }

    .dd-pointer {
        cursor: pointer;
    }

    .font-12 {
        font-size: 12px;
        color: #2259e5;
    }

    .img {
        font-size: 18px;
        font-weight: bold;
        padding: 0 10px;
    }
    .head-title {
        position: absolute;
        content: "";
        display: inline-block;
        width: 4px;
        height: 16px;
        top: 33px;
        background-color: #2259e5;
        transform: translateY(-50%);
        border-radius: 2px;
    }
    .info{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 15px 0;
        border-bottom: 1px solid #f5f5f5;
    }
    .screen-list{
        padding-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        img{
            width: auto;
            height: 348px;
            min-width: 196px;
            margin-left: 12px;
        }
    }
    .el-row{
        padding-bottom: 10px;
    }
    .descriptions{
        padding: 10px;
    }
    .tabss  ::v-deep .el-card__body,::v-deep  .el-main{
        padding: 0px 20px;
    }
    ::v-deep .el-tabs__header {
        padding: 0;
        position: relative;
        margin: 0 0 0px;
    }
</style>

