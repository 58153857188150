import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        username: '',
        app: {
            version: '',
        },
        usertype: ""
    },
    mutations: {
        set_username (state, username) {
            localStorage.setItem('username', username)
            state.username = username
        },
        set_usertype (state, usertype) {
            localStorage.setItem('usertype', usertype)
            state.usertype = usertype
        }
    },
    getters: {
        username: state => {
            return state.username || localStorage.getItem('username')
        },
        usertype: state => {
            return state.usertype || localStorage.getItem('usertype')
        }
    }
})

export default store
