<template>
    <div>
        <el-row>
            <div class="control-filter dd-flex">
                <div class="control-data-num dd-flex dd-align-center">
                    <zx-count-card  type="1" title="应用实时观察台" @handelType="handelType" :appType="type" ></zx-count-card>
                    <zx-count-card  type="2" :count="countData.sjCount" :zCount="countData.zsjCount" title="新上架应用" @handelInfo="handelInfo" status="1"></zx-count-card>
                    <zx-count-card  type="2"  :count="countData.xjCount" :zCount="countData.zxjCount"  title="下架应用" @handelInfo="handelInfo" status="0"></zx-count-card>
                    <zx-count-card  type="3" :count="countData.count" :title="type=='ios'?'IOS应用总数':'安卓应用总数'"></zx-count-card>
                    <zx-count-card  type="3" :count="countData.qcount" title="应用总数"></zx-count-card>
                </div>
            </div>
        </el-row>
        <el-row :gutter="10" style="margin:10px 0">
            <el-col :xs="24" :sm="12" :md="12">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix font-500">
                        <span>App 近7天每日上架更新应用统计</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="handelInfo('1')">详情<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                    </div>
                    <div style="display: flex;align-items: center;padding-bottom: 10px">
                        <div style="padding-right: 30px">日期</div>
                        <el-radio-group v-model="stime" size="small">
                            <el-radio-button label="近7天"></el-radio-button>
                        </el-radio-group>
                    </div>
                    <line-chart :sjData="sjData" title="上架应用" />
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix font-500">
                        <span>App 近7天每日下架更新应用统计</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="handelInfo('0')">详情<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                    </div>
                    <div style="display: flex;align-items: center;padding-bottom: 10px">
                        <div style="padding-right: 30px">日期</div>
                        <el-radio-group v-model="stime" size="small">
                            <el-radio-button label="近7天"></el-radio-button>
                        </el-radio-group>
                    </div>
                    <line-chart :sjData="xjData" title="下架应用" />
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="10" style="margin:10px 0">
            <el-col :xs="24" :sm="24" :md="24">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix font-500">
                        <span>App 每日上/下架应用分类数量统计</span>
                    </div>
                    <div style="display: flex;align-items: center;padding-bottom: 10px">
                        <el-radio-group v-model="status" size="small" @change="getDataCategory">
                            <el-radio-button label="1" >上架统计分类</el-radio-button>
                            <el-radio-button label="0" >下架统计分类</el-radio-button>
                        </el-radio-group>
                    </div>
                    <pie-chart :sjData="categoryData"/>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import lineChart from './charts/lineChart'
    import pieChart from './charts/pieChart'
    export default {
        name: "dashboard",
        path: "/main/dashboard",
        components: {
            lineChart,
            pieChart
        },
        data() {
            return {
                stime:'近7天',
                status:"1",
                sjData:{},
                xjData:{},
                type:"android",
                countData:{},
                categoryData:[]
            }
        },
        methods: {
            handelInfo(status){
                if(this.type == "ios"){
                    this.$router.push({
                        path: `/main/dashboard/ios/${status}`,
                    })
                }else {
                    this.$router.push({
                        path: `/main/dashboard/android/${status}`,
                    })
                }
            },
            async getSjData() {
                try {
                    const resp = await this.$http.get(`/app/dashboard/${this.type}/1/`)
                    if (resp.status === 200) {
                        this.sjData = resp.data.data
                    } else {
                        this.$message.error(resp.data.message)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            async getXjData() {
                try {
                    const resp = await this.$http.get(`/app/dashboard/${this.type}/0/`)
                    if (resp.status === 200) {
                        this.xjData = resp.data.data
                    } else {
                        this.$message.error(resp.data.message)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            async getDataCount() {
                try {
                    const resp = await this.$http.get(`/app/dashboardNum/${this.type}/`)
                    if (resp.status === 200) {
                        this.countData = resp.data.data
                    } else {
                        this.$message.error(resp.data.message)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            async getDataCategory() {
                try {
                    const resp = await this.$http.get(`/app/dashboardCategory/${this.type}/${this.status}/`)
                    if (resp.status === 200) {
                        this.categoryData = resp.data.data
                    } else {
                        this.$message.error(resp.data.message)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            handelType(type){
                this.type = type
                this.getDataCount()
                this.getSjData()
                this.getXjData()
                this.getDataCategory()
            }
        },
        mounted() {
            this.getDataCount()
            this.getSjData()
            this.getXjData()
            this.getDataCategory()
        }
    }
</script>


<style lang="scss" scoped>
    .control-filter .control-data-num{
        flex: 1;
        background: linear-gradient(90deg,#0148d4,#3f8cff);
    }
    .dd-align-center {
        /*align-items: center;*/
        /*padding-top: 10px;*/
    }
    .dd-flex {
        display: flex;
    }
    .font-500 {
        font-family: PingFangSC-Medium,PingFang SC,sans-serif;
        font-weight: 600;
        color: #111111;
        font-size: 14px;
    }
    div {
        display: block;
    }
    .control-filter{
        width: 100%;
        height: 186px;
        color: #fff;
        overflow: hidden;
        border-radius: 4px;
    }
    .box-card {
        ::v-deep .el-card__header {
            padding-bottom: 10px !important;
            border-bottom: 1px solid #ffffff;

        }
        ::v-deep .el-card__body {
            padding: 10px 20px;
        }
        ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            background-color: #2259e5;
            border-color: #2259e5;
        }
    }
</style>

