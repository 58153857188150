<template>
    <el-dialog title="添加" :visible="visible" width="40%" @close="close">
        <el-row>
            <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="AppId" prop="qimai_id"
                              :rules="{ required: true, message: 'AppId', trigger: 'blur'}">
                    <el-input v-model.trim="form.qimai_id"></el-input>
                </el-form-item>
                <el-form-item label="应用名称" prop="app_name">
                    <el-input v-model.trim="form.app_name"></el-input>
                </el-form-item>
                <el-form-item label="应用包名" prop="bundle_id">
                    <el-input v-model.trim="form.bundle_id"></el-input>
                </el-form-item>
                <el-form-item label="公司名称" prop="company_name">
                    <el-input v-model.trim="form.company_name"></el-input>
                </el-form-item>
                <el-form-item label="应用市场" prop="market_id"
                              :rules="{ required: true, message: '应用市场', trigger: 'blur'}">
                    <el-select v-model="form.market_id" clearable placeholder="请选择" style="width: 100%">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </el-row>
        <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">提 交
                </el-button>
        </span>
    </el-dialog>
</template>
<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
        },
        data() {
            return {
                options: [
                    {"value":"1","label": "360"},
                    {"value":"2","label": "百度"},
                    {"value":"3","label": "应用宝"},
                    {"value":"4","label": "小米"},
                    {"value":"5","label": "豌豆荚"},
                    {"value":"6","label": "华为"},
                    {"value":"7","label": "魅族"},
                    {"value":"8","label": "VIVO"},
                    {"value":"9","label": "OPPO"},
                ],
                loading: false,
            }
        },
        methods: {
            async formSubmit() {
                const valid = await this.$refs['form'].validate().then(res => res).catch(error => error);
                if (!valid) return
                const resp = await this.$http.post('/android/', this.form)
                if (resp.data.code != 1) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.$message.success('保存成功')
                    this.$emit('close')
                    this.$emit('update')
                }
            },
            close() {
                this.$emit("close")
            },
        },
        mounted() {

        }
    };
</script>

<style scoped>
    /deep/ .el-dialog__body {
        padding: 0 20px;
    }
</style>
