<template>
    <zx-admin-table title="公司管理" :loading="loading" :table="table" @change="handleTableChange"
                    @row-click="handleRowChange">
        <template v-slot:title>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-download" circle @click="doDownLoad"></el-button>
                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" :dictList="dictList"/>
        </template>
        <el-table-column type="index"
                         label="#"
                         header-align="center"
                         width="120"
                         align="center">
            <template slot-scope="scope">
                <span>{{ (table.page - 1) * table.pageSize + scope.$index + 1 }}</span>
            </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
            <template slot-scope="scope">
                <div v-if="v.field =='count'">
                    <el-tag type="success" v-if="scope.row.count" size="small">{{scope.row.count}}</el-tag>
                    <el-tag type="info" v-else size="small">{{scope.row.count}}</el-tag>
                </div>
                <div v-else-if="v.field =='area'">
                    <span :style="v.field =='area'">{{ scope.row.area | filerArea}}</span>
                </div>
                <div v-else>
                    <span :style="v.field =='company_name'?'color: #4c91f6':''" @click="handleCompany(scope.row)">{{ scope.row[v.field]}}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
                <el-button @click="handleDel(scope.row)"
                           size="mini" circle type="danger"
                           icon="el-icon-delete">
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogClose" @update="getData"></data-form>
        </template>
    </zx-admin-table>
</template>

<script>
    import SearchForm from './widgets/searchForm'
    import DataForm from './widgets/dataForm'

    import data from './data'
    let that;
    export default {
        name: 'company',
        path: '/main/company/index',
        components: {
            SearchForm,
            DataForm
        },
        data() {
            return {
                loading: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                form: {},
                search: {},
                dictList: [],
            }
        },
        filters: {
            filerArea(value) {
                for (let i = 0; i < that.dictList.length; i++) {
                    if (that.dictList[i].dictValue == value) {
                        return that.dictList[i].dictLabel
                    }
                }
            },
        },
        beforeCreate: function () {
            that = this;
        },
        methods: {
            async handleDel(row) {
                const confirm = await this.$confirm(`确认删除 ${row.company_name} 的记录吗？`,"确认删除").catch(()=>{})
                if (!confirm) return
                this.$http.delete(`/company/${row.pk}/`)
                    .then(resp => {
                        if (resp.data.code == 1) {
                            this.getData()
                            this.$message.success("删除成功！")
                        }
                    }).catch(err => {
                    this.$message.error(err.message)
                }).finally(() => {
                    this.loading = false
                })
            },
            dialogClose() {
                this.dialogVisible = false
            },
            async doDownLoad() {
                var that = this
                const resp = await this.$http.post(`/company/report/`, this.search)
                if (resp.status === 200) {
                    this.$message.success("导出任务正在进行中，请前往文件管理下载！")
                    setTimeout(function()  {
                        that.$router.push({
                            path: `/main/file/index`,
                        })
                    }, 2000);

                } else {
                    this.$message.error(resp.data.message)
                }
            },
            handleAdd() {
                this.form = {}
                this.dialogVisible = true
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleClose() {
                this.dialogVisible = false
            },
            handleCompany(row) {
                this.$router.push({
                    path: `/main/company/app/${row.company_name}`,
                })
            },
            async handleUpdate(row) {
                this.form = row
                this.dialogVisible = true
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                try {
                    var start = ''
                    var end = ''
                    if (this.search.daterange && this.search.daterange.length) {
                        start = this.search.daterange[0] || ''
                        end = this.search.daterange[1] || ''
                    }
                    this.search.start = start;
                    this.search.end = end;
                    const resp = await this.$http.get(`/company?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    if (resp.status === 200) {
                        this.table.data = resp.data.data
                        this.table.total = resp.data.total
                    } else {
                        this.$message.error(resp.data.message)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            async getdictType() {
                try {
                    const resp = await this.$http.get(`/system/dictData/company_area/?page=0&pageSize=1000`)
                    if (!resp.data) {
                        this.dictList = []
                    } else {
                        this.dictList = resp.data.data
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
        },
        mounted() {
            this.getdictType()
            this.getData()
        }
    }
</script>

<style scoped>
    ::v-deep .el-tag--small {
        width: 40px !important;
    }
</style>
