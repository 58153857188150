const data = {
    table: {
        columns: [
            {title: '公司中文名称', field: 'company_name', width: '300'},
            {title: '公司英文名称', field: 'company_name_en', width: '400'},
            {title: '创建时间', field: 'created', width: ''},

        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
