const data = {
    table: {
        columns: [
            {title: '账号', field: 'username', width: ''},
            {title: '名称', field: 'nickname', width: ''},
            {title: '类型', field: 'typename', width: ''},
            {title: '区域', field: 'area', width: ''},
            // {title: '状态', field: 'is_delete', width: ''},
            {title: '角色', field: 'roleName', width: ''},
            {title: '邮件', field: 'email', width: ''},
            {title: '创建时间', field: 'created', width: ''},
            {title: '最后登录时间', field: 'last_login', width: '120'}

        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
