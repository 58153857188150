<template>
    <base-layout @change="handleCollapse">
        <el-aside :width="menuWidth">
            <aside-menu :default-active="$route.path" :menuItems="menuItems" :isCollapse="isCollapse"/>
        </el-aside>
        <el-main>
            <router-view></router-view>
        </el-main>
    </base-layout>
</template>

<script>
    import BaseLayout from "./components/baseLayout"
    import AsideMenu from "./components/asideMenu"
    import menu from "@/data/menu"
    export default {
        components: {
            BaseLayout,
            AsideMenu
        },
        data() {
            return {
                menuWidth:"180px",
                menuItems: [],
                isCollapse:false
            }
        },
        methods: {
            filterArrForKey(arr, key, value) {
                let newArr = []
                newArr = arr.map(item => {
                    if (item.children && item.children != null) {
                        item = {
                            ...item,
                            children: this.filterArrForKey(item.children, key, value)
                        }
                    }
                    if (value.includes(item[key])) {
                        return item
                    } else {
                        return null
                    }
                })
                newArr = newArr.filter(item => item != null)
                return newArr
            },
            getData(){
                // this.$http.get('/menu')
                //     .then(resp=>{
                this.getMenu(menu.menuItems)
                    // }).catch(err=>{
                    // this.$message.error('获取菜单失败'+err.message)
                // })
            },
            async getMenu(menuItems){
                const resp = await this.$http.get(`/auth/menu/`)
                if (resp.data.menus.length > 0) {
                    this.menuItems = this.filterArrForKey(menuItems, 'id', resp.data.menus)
                } else {
                    this.menuItems = menuItems
                }
            },
            handleCollapse(){
                this.isCollapse = !this.isCollapse
                this.menuWidth = this.isCollapse ? '65px' : '180px'
            }
        },

        mounted() {
            this.getData()
        }
    }
</script>
<style>
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    /* 滚动条有滑块的轨道部分 */
    ::-webkit-scrollbar-track-piece {
        background-color: transparent;
        border-radius: 5px;
    }

    /* 滚动条滑块(竖向:vertical 横向:horizontal) */
    ::-webkit-scrollbar-thumb {
        cursor: pointer;
        background-color:rgba(144,147,153,.3);
        border-radius: 5px;
    }

    /* 滚动条滑块hover */
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(144,147,153,.3);
    }

    /* 同时有垂直和水平滚动条时交汇的部分 */
    ::-webkit-scrollbar-corner {
        display: block;    /* 修复交汇时出现的白块 */
    }
</style>
<style lang="scss" scoped>
    .el-aside {
        background-color: rgb(51, 57, 89);
        color: #fff;
        /*overflow-y: scroll;*/
        overflow-x: hidden;
    }
</style>
