<template>
    <el-menu style="border:none;width:100%;" router :default-active="activeItem" :unique-opened="true"
             background-color="rgb(51, 57, 89)" class="aside-menu"
             text-color="#fff"
             active-text-color="#409eff" :collapse="isCollapse">
<!--        <div v-for="(menu, idx) in menuItems" :key="idx">-->
            <el-submenu v-if="menu.children && menu.children.length >= 1" :index="menu.path" v-for="(menu, idx) in menuItems" :key="idx">
                <template slot="title">
                    <i :class="menu.icon"></i>
                    <span slot="title">{{ menu.label }}</span>
                </template>
                <el-menu-item v-for="(sb, sid) in menu.children" :key="sid" :index="sb.path">{{sb.label}}</el-menu-item>
            </el-submenu>
            <el-menu-item v-else :index="menu.path">
                <i :class="menu.icon"></i>
                <span slot="title">{{ menu.label }}</span>
            </el-menu-item>
<!--        </div>-->
    </el-menu>
</template>

<script>
    export default {
        name: 'AsideMenu',
        props: {
            menuItems: {
                type: Array,
                required: true
            },
            isCollapse:{
                default:false
            }
        },
        data() {
            return {
            };
        },
        computed: {
            activeItem() {
                return this.getActive();
            },
        },
        mounted() {
            // console.log(this.menu)
        },
        methods: {
            getActive(){
                if(this.$route.path.indexOf("/main/ios/app/info")>-1)
                    return '/main/app/ios';
                else if (this.$route.path.indexOf("/main/android/app/info")>-1)
                    return '/main/app/android';
                else if (this.$route.path.indexOf("/main/company/app/")>-1)
                    return '/main/company/index';
                else if (this.$route.path.indexOf("/main/company/android/app/info/")>-1)
                    return '/main/company/index';
                else if (this.$route.path.indexOf("/main/company/ios/app/info/")>-1)
                    return '/main/company/index';
                else if (this.$route.path.indexOf("main/dashboard/android/")>-1)
                    return '/main/dashboard';
                else if (this.$route.path.indexOf("main/dashboard/ios/")>-1)
                    return '/main/dashboard';
                else
                    return this.$route.path;
            },
        }
    };
</script>

<style scoped>
/*::v-deep .el-submenu .el-menu-item {*/
/*    min-width: 150px;*/
/*}*/
</style>
