<template>
    <zx-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="字典名称">
            <el-input v-model="form.type" clearable placeholder="字典名称"></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
            <el-date-picker clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-model="form.daterange" style="float: right; padding: 3px 10px" type="daterange" value-format="yyyy-MM-dd">
            </el-date-picker>
        </el-form-item>
    </zx-search-form-inline>
</template>

<script>
    export default {
        data() {
            return {
                form: {},
            }
        },
        methods: {
            doSearch() {
                this.$emit('search', this.form)
            }
        }
    }
</script>

