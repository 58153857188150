<template>
    <zx-admin-table title="公司中英管理" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:title>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-plus" type="warning" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" />
        </template>
        <el-table-column type="index"
                         label="#"
                         header-align="center"
                         width="120"
                         align="center">
            <template slot-scope="scope">
                <span>{{ (table.page - 1) * table.pageSize + scope.$index + 1 }}</span>
            </template>
        </el-table-column>
        <el-table-column  show-overflow-tooltip
                          v-for="(v, idx) in table.columns"
                          :prop="v.field"
                          :label="v.title"
                          :width="v.width"
                          :formatter="v.formatter"
                          :key="idx">
        </el-table-column>
        <el-table-column align="center" label="操作" width="260">
            <template slot-scope="scope">
                <el-button @click="handleUpdate(scope.row)"
                           size="mini"
                           type="warning" circle
                           icon="el-icon-edit">
                </el-button>
                <el-button @click="handleDel(scope.row)"
                           size="mini"
                           type="danger" circle
                           icon="el-icon-delete">
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <el-dialog
                    title="添加/修改"
                    :visible.sync="model1"
                    width="40%"
            >
                <el-form :model="form" :rules="rules" ref="form" label-width="120px" style="padding-left:10%;">
                    <el-form-item label="公司中文名称" prop="company_name">
                        <el-input v-model="form.company_name"></el-input>
                    </el-form-item>
                    <el-form-item label="公司英文名称">
                        <el-input v-model="form.company_name_en"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                <el-button @click="model1 = false">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
            </el-dialog>
            <el-dialog
                    title="删除"
                    :visible.sync="dialogVisible"
                    width="30%"
            >
                <span style="text-align: center;">您确定删除数据吗？</span>
                <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="delSubmit">确 定</el-button>
            </span>
            </el-dialog>
        </template>
    </zx-admin-table>
</template>

<script>
    import SearchForm from './widgets/searchForm'
    import data from './data'
    export default {
        name: 'cnen',
        path: '/main/settings/cnen',
        components: {
            SearchForm,
        },
        data() {
            return {
                model1: false,
                loading: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                form: {
                    company_name:"",
                    company_name_en:""
                },
                treeList:[],
                search: {},
                rules: {
                    company_name: [
                        {required: true, message: '请选输入', trigger: 'blur'},
                    ]
                }
            };
        },
        methods: {
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleAdd() {
                this.form = {
                    company_name:"",
                    company_name_en:""
                }
                this.model1 = true
            },
            handleDel(row) {
                this.delpk = row.pk
                this.dialogVisible = true
            },
            handleUpdate(row) {
                this.form = {
                    pk:row.pk,
                    company_name:row.company_name,
                    company_name_en:row.company_name_en
                }
                this.model1 = true
            },
            delSubmit() {
                this.$http.delete(`/company/cnen/${this.delpk}/`)
                    .then(resp => {
                        if (resp.data.code == 1) {
                            this.$message({
                                message: '提交成功',
                                type: 'success'
                            })
                            this.dialogVisible = false
                            this.getData()
                        } else {
                            this.$message({
                                message: resp.data.msg,
                                type: 'error'
                            })
                        }
                    })
            },
            addSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        var method = this[`form`]['pk'] !== undefined ? 'put' : 'post'
                        this.$http[method]('/company/cnen/', this.form)
                            .then(resp => {
                                if (resp.data.code == 1) {
                                    this.$message({
                                        message: '提交成功',
                                        type: 'success'
                                    })
                                    this.model1 = false
                                    this.getData()
                                } else {
                                    this.$message({
                                        message: resp.data.msg,
                                        type: 'error'
                                    })
                                }
                            })
                    }
                })
            },
            getData() {
                this.loading = true
                this.$http.get(`/company/cnen/?page=${this.table.page}&pageSize=${this.table.pageSize}`,{params: this.search})
                    .then(resp => {
                        this.table.data = resp.data.data
                        this.table.total = resp.data.total
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
            },
        },
        mounted() {
            this.getData();
        }
    }
</script>
