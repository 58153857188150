const data = {
    table: {
        columns: [
            {title: '公司名称', field: 'company_name', width: '300'},
            {title: '统一信用代码', field: 'social_code', width: ''},
            {title: '公司地址', field: 'address', width: ''},
            {title: '区域', field: 'area', width: ''},
            {title: '创建时间', field: 'created', width: ''},
            {title: '更新时间', field: 'updated', width: ''},
            {title: '应用数量', field: 'count', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
