import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)


function getRoutes(requireComponent) {
    let routes = []
    requireComponent.keys().forEach(filename => {
        // filename 文件
        // 第i个组件
        const config = requireComponent(filename)
        // 组件名
        if (config.default.path) {
            // console.log(config.default)
            if (config.default.path instanceof Array) {
                config.default.path.forEach(p => {
                    // console.log(p)
                    routes.push({
                        path: p,
                        component: config.default
                    })
                })
            } else {
                routes.push({
                    path: config.default.path,
                    name: config.default.name,
                    // component: ()=>import(`@/components/${sub.componentName}`)
                    component: config.default
                })
            }
        }
    })
    return routes
}


const router = new Router({
    mode: 'history',
    routes: [
        {path: '/', redirect: '/login'},
        {path: '/login', component: () => import(`@/pages/main/login`)},
        {path: '/logout', component: () => import(`@/pages/main/logout`)},
        {
            path: '/main/password', component: () => import(`@/pages/main/layout_blank`),
            children: [
                {path: '', component: () => import(`@/pages/main/password`)},
            ]
        },
        {
            path: '/main', component: () => import(`@/pages/main/layout_admin`),
            children: getRoutes(require.context(`@/pages`, true, /\.vue$/)).concat([
                {path: '*', component: () => import(`@/pages/main/notFound`)}
            ]),
        },
        {path: '*', component: () => import(`@/pages/main/notFound`)}
    ]
})

export default router
