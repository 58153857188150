<template>
    <zx-admin-table title="用户管理" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:title>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-plus" type="warning" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" />
        </template>
        <el-table-column type="index"
                         label="#"
                         header-align="center"
                         width="120"
                         align="center">
            <template slot-scope="scope">
                <span>{{ (table.page - 1) * table.pageSize + scope.$index + 1 }}</span>
            </template>
        </el-table-column>
        <el-table-column  show-overflow-tooltip
                          v-for="(v, idx) in table.columns"
                          :prop="v.field"
                          :label="v.title"
                          :width="v.width"
                          :formatter="v.formatter"
                          :key="idx">
            <template slot-scope="scope">
                <div v-if="v.field =='typename' ">
                    <el-tag v-if="scope.row.type=='3'" size="small" type="danger">{{ scope.row.typename }}</el-tag>
                    <el-tag v-else-if="scope.row.type=='0'" size="small">{{ scope.row.typename }}</el-tag>
                    <el-tag v-else-if="scope.row.type=='1'" type="success" size="small">{{ scope.row.typename }}</el-tag>
                    <el-tag v-else type="warning" size="small">{{ scope.row.typename }}</el-tag>
                </div>
                <div v-else-if="v.field =='area'">
                    <span :style="v.field =='area'">{{ scope.row.area | filerArea}}</span>
                </div>
                <div v-else>
                    <span >{{ scope.row[v.field]}}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
                <el-button @click="handleUpdate(scope.row)"
                           size="mini" circle
                           type="warning"
                           icon="el-icon-edit">
                </el-button>
                <el-button
                        size="mini" circle
                        type="info" icon="el-icon-refresh-left"
                        @click="handleReact(scope.row)"
                >
                </el-button>
                <el-button @click="handleDel(scope.row)"
                           size="mini" circle type="danger"
                           icon="el-icon-delete">
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <el-dialog
                    title="删除"
                    :visible.sync="dialogVisible"
                    width="30%"
            >
                <span style="text-align: center;">您确定删除此用户吗？</span>
                <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="delSubmit">确 定</el-button>
            </span>
            </el-dialog>
            <el-dialog
                    title="添加/修改账号"
                    :visible.sync="model1"
                    width="50%"
            >
                <el-form :rules="rules" ref="accountform" :model="accountform" label-width="80px" style="padding-left:10%;">
                    <el-form-item label="账号" prop="username">
                        <el-input v-model="accountform.username" style="width:80%"></el-input>
                    </el-form-item>
                    <el-form-item label="名称" prop="nickname">
                        <el-input v-model="accountform.nickname" style="width:80%"></el-input>
                    </el-form-item>
                    <el-form-item label="区域" prop="area">
                        <el-select v-model="accountform.area" placeholder="请选择" clearable style="width:80%">
                            <el-option :label="item.dictLabel" :value="item.pk" v-for="item in dictList" :key="item.pk">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="账号类型" prop="type">
                        <el-select v-model="accountform.type" placeholder="请选择" style="width:80%">
                            <el-option label="普通用户" value="0">
                            </el-option>
                            <el-option label="管理员" value="1">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="角色" prop="roles">
                        <el-select v-model="accountform.roles" placeholder="请选择" style="width:80%">
                            <el-option :label="item.name" :value="item.pk" v-for="item in roleList" :key="item.pk">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="用户密码" v-if="operator == 'add'">
                        <el-input v-model="accountform.password" type="password" style="width:80%" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="用户邮件" prop="email">
                        <el-input v-model="accountform.email" style="width:80%"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                <el-button @click="model1 = false">取 消</el-button>
                <el-button type="primary" @click="addSubmit()">确 定</el-button>
            </span>
            </el-dialog>
            <el-dialog
                    title="重置密码"
                    :visible.sync="model3"
                    width="30%"
            >
                <el-form :rules="rules" ref="accountReactform" :model="accountReactform" label-width="80px"
                         style="padding-left:10%;">
                    <el-form-item label="密码" prop="password">
                        <el-input v-model="accountReactform.password" type="password" show-password style="width:80%"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                <el-button @click="model3 = false">取 消</el-button>
                <el-button type="primary" @click="reactSubmit()">确 定</el-button>
            </span>
            </el-dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogVisible=false" @update="getData"></data-form>
        </template>
    </zx-admin-table>
</template>

<script>
    import SearchForm from './widgets/searchForm'
    import DataForm from './widgets/dataForm'
    import data from './data'
    let that;
    export default {
        name: 'user',
        path: '/main/settings/user',
        components: {
            SearchForm,
            DataForm,
        },
        data() {
            var validateEmail = (rule, value, callback) => {
                var reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
                if (reg.test(value)) {
                    callback();
                } else {
                    callback(new Error('邮件格式不对！'));
                }
            };
            var validateUsername = (rule, value, callback) => {
                // eslint-disable-next-line
                var regex = /^[A-Za-z0-9_\-]+$/ig
                if (regex.test(value)) {
                    callback();
                } else {
                    callback(new Error('账号格式不对！'));
                }
            };
            return {
                roleList:[],
                model1: false,
                model3: false,
                keywords: "",
                operator: "add",
                loading: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                form: {},
                search: {},
                formInline: {
                    daterange: []
                },
                tableData: [],
                accountform:{},
                fileList: [],
                accountReactform: {
                    password: '',
                    pk: null
                },
                dictList: [],
                rules: {
                    username: [
                        {validator: validateUsername, trigger: 'blur'},
                    ],
                    roles: [
                        {required: true, message: '请选择角色', trigger: 'blur'},
                    ],
                    type: [
                        {required: true, message: '请选择用户类型', trigger: 'blur'},
                    ],
                    email: [
                        {validator: validateEmail, trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 4, max: 64, message: '长度在 4 到 64 个字符', trigger: 'blur'}
                    ]
                }
            };
        },
        beforeCreate: function () {
            that = this;
        },
        filters: {
            filerArea(value) {
                for (let i = 0; i < that.dictList.length; i++) {
                    if (that.dictList[i].dictValue == value) {
                        return that.dictList[i].dictLabel
                    }
                }
            },
        },
        methods: {
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleReact(row) {
                this.accountReactform.pk = row.pk
                this.model3 = true
            },
            reactSubmit() {
                this.$refs['accountReactform'].validate((valid) => {
                    if (valid) {
                        this.$http.post('/auth/react/', this.accountReactform)
                            .then(resp => {
                                if (resp.data.code == 1) {
                                    this.$message({
                                        message: '提交成功',
                                        type: 'success'
                                    })
                                    this.model3 = false
                                    this.getData()
                                } else {
                                    this.$message({
                                        message: resp.data.msg,
                                        type: 'error'
                                    })
                                }
                            })
                    }
                })
            },
            handleAdd() {
                this.accountform = {
                    password: '',
                    username: '',
                    email: '',
                    nickname: '',
                    roles: '',
                    type: '0',
                    area: ''
                }
                this.operator = "add"
                this.model1 = true
            },
            handleDel(row) {
                this.delpk = row.pk
                this.dialogVisible = true
            },
            handleUpdate(row) {
                this.accountform = {
                    pk: row.pk,
                    username: row.username,
                    email: row.email,
                    nickname: row.nickname,
                    roles: row.roles,
                    type: row.type,
                    area: row.area
                }
                this.operator = "edit"
                this.model1 = true
            },
            delSubmit() {
                this.$http.delete(`/auth/${this.delpk}/`)
                    .then(resp => {
                        if (resp.data.code == 1) {
                            this.$message({
                                message: '提交成功',
                                type: 'success'
                            })
                            this.dialogVisible = false
                            this.getData()
                        } else {
                            this.$message({
                                message: resp.data.msg,
                                type: 'error'
                            })
                        }
                    })
            },
            addSubmit() {
                this.$refs['accountform'].validate((valid) => {
                    if (valid) {
                        var method = this[`accountform`]['pk'] !== undefined ? 'put' : 'post'
                        this.$http[method]('/auth/', this.accountform)
                            .then(resp => {
                                if (resp.data.code == 1) {
                                    this.$message({
                                        message: '提交成功',
                                        type: 'success'
                                    })
                                    this.model1 = false
                                    this.getData()
                                } else {
                                    this.$message({
                                        message: resp.data.msg,
                                        type: 'error'
                                    })
                                }
                            })
                    }
                })
            },
            getRole() {
                this.$http.get(`/auth/role/`)
                    .then(resp => {
                        this.roleList = resp.data.data
                    })
                    .catch(() => {
                    })
            },
            getData() {
                var start = ''
                var end = ''
                if (this.formInline.daterange && this.formInline.daterange.length) {
                    start = this.formInline.daterange[0] || ''
                    end = this.formInline.daterange[1] || ''
                }
                this.loading = true
                this.$http.get(`/auth/?page=${this.table.page}&pageSize=${this.table.pageSize}&start=${start}&end=${end}`)
                    .then(resp => {
                        this.table.data = resp.data.data
                        this.table.total = resp.data.total
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
            },
            async getdictType() {
                try {
                    const resp = await this.$http.get(`/system/dictData/company_area/?page=0&pageSize=1000`)
                    if (!resp.data) {
                        this.dictList = []
                    } else {
                        this.dictList = resp.data.data
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
        },
        mounted() {
            this.getRole();
            this.getdictType();
            this.getData();
        }
    }
</script>

<style scoped>
    ::v-deep .el-tag--small {
        width: 65px !important;
    }
</style>
