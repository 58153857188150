<template>
    <zx-admin-table title="IOS" :loading="loading" :table="table" @change="handleTableChange" @row-click="handleRowChange">
        <template v-slot:title>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" />
        </template>
        <el-table-column type="index"
                         label="#"
                         header-align="center"
                         width="120"
                         align="center">
            <template slot-scope="scope">
                <span>{{ (table.page - 1) * table.pageSize + scope.$index + 1 }}</span>
            </template>
        </el-table-column>
        <el-table-column  show-overflow-tooltip
                          v-for="(v, idx) in table.columns"
                          :prop="v.field"
                          :label="v.title"
                          :width="v.width"
                          :formatter="v.formatter"
                          :key="idx">
            <template slot-scope="scope">
                <div v-if="v.field !='online'" class="overflow-tooltip">
                    <div v-if="v.field =='app_name'" class="appicon">
                        <div>
                            <el-image :src="scope.row.icon" class="img">
                                <div slot="error" class="image-slot">
                                    <img src="../../../../assets/app.png" class="img">
                                </div>
                            </el-image>
                        </div>
                        <div style="padding-left: 10px;color: #4c91f6" class="overflow-tooltip">
                            <div>{{ scope.row.app_name}}</div>
                            <div class="overflow-tooltip">{{ scope.row.developer}}</div>
                        </div>
                    </div>
                    <el-rate v-else-if="v.field =='current_version_user_rating'" :value="parseInt(scope.row.current_version_user_rating)"   disabled  text-color="#ff9900"></el-rate>
                    <span v-else >{{ scope.row[v.field]}}</span>

                </div>
                <div v-else>
                    <el-tag type="info" v-if="scope.row.online=='0'" size="small">下架</el-tag>
                    <el-tag type="success" v-else size="small">在架</el-tag>
                </div>
            </template>
        </el-table-column>
    </zx-admin-table>
</template>

<script>
    import SearchForm from './widgets/searchForm'
    import data from './data'
    export default {
        name: 'dashboard-ios',
        path: '/main/dashboard/ios/:status',
        components: {
            SearchForm,
        },
        data() {
            return {
                loading: false,
                dialogVisible: false,
                table: data.table,
                searchVisible: true,
                form: {
                },
                search: {}
            }
        },
        methods: {
            handleRowChange(row){
                this.$router.push({
                    path: `/main/dashboard/ios/app/info/${row.market_id}/${row.pk}`,
                })
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            async getData() {
                this.loading = true
                try {
                    if (this.search.daterange && this.search.daterange.length) {
                        this.search.start = this.search.daterange[0] || ''
                        this.search.end = this.search.daterange[1] || ''
                    }
                    let status = this.$route.params.status;
                    const resp = await this.$http.get(`/app/dashboardIos/${status}/?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    if (resp.status === 200) {
                        this.table.data = resp.data.data
                        this.table.total = resp.data.total
                    } else {
                        this.$message.error(resp.data.message)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>
<style lang="scss" scoped>
    .primary {
        background-color: #67c23a;
        border-color: #67c23a;
        color: white;
    }

    .info {
        background-color: #e4e4e4;
        border-color: #e4e4e4;
        color: #909399;
    }

    .el-tag--mini {
        width: 60%;
        height: 23px;
        padding: 0 5px;
        line-height: 20px;
        text-align: center;
        border-color: transparent;
    }

    .spans {
        color: #409eff;
        margin: 5px;
    }

    .eltag {
        margin-left: 10px;
    }

    .eltag span {
        color: red;
    }

    ::v-deep .el-tag--small {
        height: 30px;
        padding: inherit;
        line-height: 30px;
        width: 115px;
        text-align: center;
    }

    ::v-deep .el-tag--plain {
        background-color: #fff;
        border-color: #cccccc;
        color: black;
    }



    .search-title span {
        margin-left: 20px;
    }

    ::v-deep .el-card__body {
        padding: 20px 0;
    }


    ::v-deep .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 10px;
    }
    .page-footer{
        margin-top: 20px;
        text-align: center
    }
    .title {
        display: flex;
        margin: 0 20px;
        margin-bottom: 20px;
        position: relative;

        .img {
            border: 1px solid #eee;
            border-radius: 12px;
            border-radius: 24%;
            display: block;
            height: 70px;
            width: 70px;
            margin-right: 15px;
        }

        .title-text {
            display: inline-flex;
            flex-direction: column;
            justify-content: space-around;
        }

        .p-title {
            color: #222;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .app-info-card {
        transition: all .5s;
        width: 100%;
        height: 102px;
        background: #fbfbfb;
        padding: 0 23px;
        position: relative;
    }

    .dd-flex {
        display: flex;
    }
    .appicon{
        .img {
            width: 44px;
            height: 44px;
            border-radius: 8px;
        }
        display: flex;
        align-items: center;
    }

     ::v-deep .el-tag--small {
         width: 65px !important;
     }
    .overflow-tooltip{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
    }
</style>
