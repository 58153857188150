<template>
    <div class="app-info-card dd-flex">
        <div class="app-info-card-item" v-for="i in [1,2,3,4,5,6,7]" :key="i">
            <div class=" dd-flex dd-flex-center dd-desc-color">
                <div v-if="i==1">Bundle ID</div>
                <div v-if="i==2">{{form.raw_data.app_comment_count }}个评论</div>
                <div v-if="i==3">分类榜</div>
                <div v-if="i==4">价格</div>
                <div v-if="i==5">开发者</div>
                <div v-if="i==6">累计下载</div>
                <div v-if="i==7">最新更新</div>
            </div>
            <div class="app-value dd-flex dd-flex-center dd-align-center dd-base-color font-16 font-500">
                <div v-if="i==1" >{{form.bundle_id}}</div>
                <div v-if="i==2">{{form.raw_data.app_comment_score}}</div>
                <div v-if="i==3">第{{form.raw_data.app_category_rank}}名</div>
                <div v-if="i==4">免费</div>
                <div v-if="i==5"><i class="el-icon-s-custom" /> </div>
                <div v-if="i==6">{{form.raw_data.download_num}}</div>
                <div v-if="i==7">{{form.raw_data.app_version}}</div>
            </div>
            <div class="app-desc-value dd-flex dd-flex-center">
                <el-link  v-if="i==1" :href="form.raw_data.app_url" class="download-icon font-12 dd-pointer el-popover__reference" target="_blank" type="primary">下载APP</el-link>
                <el-rate v-if="i==2" :value="parseInt(form.raw_data.app_comment_score)"  disabled text-color="#ff9900"></el-rate>
                <span v-if="i==3">{{form.app_category||"未分类"}}</span>
                <span v-if="i==4">应用</span>
                <span v-if="i==5">{{form.developer}}</span>
                <span v-if="i==6">-</span>
                <span v-if="i==7">{{form.app_version_time}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "zx-card-android-info",
        props :{
            form:{},
        },
        data() {
            return {
                rating:0
            }
        },
        filters: {
            fileterValue(value) {
                if (!value) return '无评论';
                var current = value.get("current","")
                if (current){
                    return current.get("ratingCount")
                }
                return "无评论"
            },
            fileterValue2(value) {
                if (!value) return '0';
                var current = value.get("current","")
                if (current){
                    this.rating = current.get("ratingAverage")
                    return current.get("ratingAverage")
                }
                return "0"
            },

        }

    }
</script>


<style lang="scss" scoped>
    .app-info-card {
        transition: all .5s;
        width: 100%;
        height: 102px;
        background: #fbfbfb;
        padding: 0 23px;
        position: relative;
    }

    .dd-flex {
        display: flex;
    }

    .app-value{
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .app-info-card .app-info-card-item {
        padding-top: 13px;
        flex: 1;
        word-break: break-all;
        overflow: hidden;
        height: 100%;
    }

    app-info-card .app-info-card-item .title {
        width: 100%;
        font-size: 12px;
    }

    .dd-desc-color {
        color: #74757a;
    }

    .dd-flex-center {
        justify-content: center;

    }

    .app-info-card .app-info-card-item .app-value {
        width: 100%;
        height: 40px;
        line-height: 40px;
    }

    .app-info-card .app-info-card-item .app-desc-value {
        width: 100%;
        font-size: 12px;
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .dd-base-color {
        color: #111;
    }

    .dd-align-center {
        align-items: center;
    }

    .font-16 {
        font-size: 16px;
    }

    .font-500 {
        font-family: PingFangSC-Medium, PingFang SC, sans-serif;
        font-weight: 600;
    }

    .dd-pointer {
        cursor: pointer;
    }

    .font-12 {
        font-size: 12px;
        color: #2259e5;
    }

</style>
