<template>
    <zx-admin-table title="任务管理" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:title>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" />
        </template>
        <el-table-column type="index"
                         label="#"
                         header-align="center"
                         width="120"
                         align="center">
            <template slot-scope="scope">
                <span>{{ (table.page - 1) * table.pageSize + scope.$index + 1 }}</span>
            </template>
        </el-table-column>
        <el-table-column type="expand">
            <template slot-scope="scope">
                <div style="padding: 0px 10px">{{scope.row.remark}}</div>
            </template>
        </el-table-column>
        <el-table-column  show-overflow-tooltip
                          v-for="(v, idx) in table.columns"
                          :prop="v.field"
                          :label="v.title"
                          :width="v.width"
                          :formatter="v.formatter"
                          :key="idx">
            <template slot-scope="scope">
                <span v-if="v.field !='status'">{{ scope.row[v.field]}}</span>
                <div v-else>
                    <el-tag type="success" v-if="scope.row[v.field]=='1'" size="small">已完成</el-tag>
                    <el-tag type="warning" v-else-if="scope.row[v.field]=='0'" size="small">进行中</el-tag>
                    <el-tag type="danger" v-else size="small">失败</el-tag>
                </div>
            </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
            <template slot-scope="scope">
                <el-button @click="handleUpdate(scope.row)"
                           size="small"
                           type="primary" circle
                           v-if="scope.row.status=='2' || scope.row.status=='3'"
                           icon="el-icon-message">
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <el-dialog
                    title="发送任务"
                    :visible.sync="model1"
                    width="30%"
            >
                <span style="text-align: center;">您确定重新发送任务吗？</span>
                <span slot="footer" class="dialog-footer">
              <el-button @click="model1 = false">取 消</el-button>
              <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
            </el-dialog>
        </template>
    </zx-admin-table>
</template>

<script>
    import SearchForm from './widgets/searchForm'
    import data from './data'
    export default {
        name: 'task',
        path: '/main/task/index',
        components: {
            SearchForm,
        },
        data() {
            return {
                model1: false,
                loading: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                form: {},
                search: {},
                pk: null
            }
        },
        methods: {
            addSubmit() {
                this.$http.post('/task/', {pk:this.pk})
                    .then(resp => {
                        if (resp.data.code == 1) {
                            this.$message({
                                message: '提交成功',
                                type: 'success'
                            })
                            this.model1 = false
                            this.getData()
                        } else {
                            this.$message({
                                message: resp.data.msg,
                                type: 'error'
                            })
                        }
                    })
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleClose() {
                this.dialogVisible = false
            },
            handleUpdate(row) {
                this.model1 = true
                this.pk = row.pk
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                try {
                    const resp = await this.$http.get(`/task/?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    if (resp.status === 200) {
                        this.table.data = resp.data.data
                        this.table.total = resp.data.total
                    } else {
                        this.$message.error(resp.data.message)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    ::v-deep .el-tag--small {
        width: 65px !important;
    }
</style>
