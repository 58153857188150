<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'app',
    created() {
        document.title = '中锐泓'
    }
}
</script>

<style>
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

#app {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 100%;
    background-color: rgb(245, 247, 251);
    color: rgb(17, 17, 17);
    /*overflow: overlay;*/
}

.el-container {
    height: 100%;
    overflow: hidden;
}

.el-message {
    min-width: 100px !important;
}
.el-tooltip__popper.is-light {
    background: #00b595 !important;
    color: white !important;
    border: 1px solid #00b595 !important;
    z-index: inherit !important;
}

.el-tooltip__popper {
    padding: 5px !important;
}
</style>
