<template>
    <zx-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="应用名称/包名/开发者/公司名称">
            <el-input v-model.trim="form.name" clearable placeholder="请输入应用名称/包名/开发者/公司名称"></el-input>
        </el-form-item>
        <el-form-item label="日期">
            <el-date-picker v-model="form.daterange"
                            :clearable="false"
                            style="float: right; padding: 3px 10px"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd">
            </el-date-picker>
        </el-form-item>
    </zx-search-form-inline>
</template>

<script>
    export default {
        data() {
            let now = new Date();
            now = now.format("yyyy-MM-dd");
            return {
                form: {
                    daterange: [now, now]
                },
            }
        },
        methods: {
            doSearch() {
                if(this.form.daterange.length==0){
                    this.$message.warning("时间必填项")
                    return
                }
                this.$emit('search', this.form)
            }
        },
        mounted() {
            this.doSearch()
        }
    }
</script>

