<template>
    <el-dialog title="添加" :visible="visible" width="40%" @close="close">
        <el-row>
            <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="AppId" prop="qimai_id" :rules="{ required: true, message: 'AppId', trigger: 'blur'}">
                    <el-input v-model.trim="form.qimai_id"></el-input>
                </el-form-item>
                <el-form-item label="应用名称" prop="app_name">
                    <el-input v-model.trim="form.app_name"></el-input>
                </el-form-item>
                <el-form-item label="应用包名" prop="bundle_id">
                    <el-input v-model.trim="form.bundle_id"></el-input>
                </el-form-item>
                <el-form-item label="公司名称" prop="company_name">
                    <el-input v-model.trim="form.company_name"></el-input>
                </el-form-item>
<!--                <el-form-item label="开发者" prop="developer">-->
<!--                    <el-input v-model.trim="form.developer"></el-input>-->
<!--                </el-form-item>-->
            </el-form>
        </el-row>
        <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">提 交
                </el-button>
        </span>
    </el-dialog>
</template>
<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
        },
        data() {
            return {
                loading: false,
            }
        },
        methods: {
            async formSubmit() {
                const valid = await this.$refs['form'].validate().then(res => res).catch(error => error);
                if (!valid) return
                const resp = await this.$http.post('/ios/', this.form)
                if (resp.data.code != 1) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.$message.success('保存成功')
                    this.$emit('close')
                    this.$emit('update')
                }
            },
            close() {
                this.$emit("close")
            },
        },
        mounted() {

        }
    };
</script>

<style scoped>
    /deep/ .el-dialog__body {
        padding: 0 20px;
    }
</style>
