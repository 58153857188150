<template>
    <v-chart autoresize :option="options" style="width: 100%;height: 100%;min-height: 300px" v-loading="loading"></v-chart>
</template>

<script>
    export default {
        name: "pieChart",
        props :{
            sjData: {
                type: Array,
                default: function() {
                    return []; // 返回一个空对象作为默认值
                }
            },
        },
        data() {
            return {
                loading: false,
                options: {
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid: {
                        top: '6%',
                        left: '1%',
                        right: '1%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'solid'
                            }
                        },
                        type: 'category',
                        data: []
                    },
                    yAxis: {
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'solid'
                            }
                        },
                        type: 'value'
                    },
                    series: [
                        {
                            data: [],
                            type: 'bar',
                            barWidth: 40,
                            markPoint: {
                                data: [
                                    { type: 'max', name: 'Max' },
                                ]
                            },
                            itemStyle: {
                                color: '#2259e5', // 设置柱状图颜色
                            },
                        }
                    ]
                }
            }
        },
        methods: {
            async getData() {
                this.loading = true
                var that = this
                setTimeout(() => {
                    var vArray = []
                    var dArray = []
                    that.sjData.forEach(function (item) {
                        vArray.push(item.count)
                        dArray.push(item.category)
                    })
                    that.options.series[0].data = vArray
                    that.options.xAxis.data = dArray
                    that.loading = false
                }, 500)
            }
        },
        watch: {
            sjData: {
                handler() {
                    this.getData();
                },
            }
        },
        mounted() {
            this.getData()
        }
    };
</script>

