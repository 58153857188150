<template>
    <zx-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="APP名称" >
            <el-input v-model.trim="form.name" clearable placeholder="请输入APP名称"></el-input>
        </el-form-item>
<!--        <el-form-item label="最后更新时间">-->
<!--            <el-date-picker v-model="form.daterange"-->
<!--                            style="float: right; padding: 3px 10px"-->
<!--                            type="daterange"-->
<!--                            value-format="yyyy-MM-dd">-->
<!--            </el-date-picker>-->
<!--        </el-form-item>-->
    </zx-search-form-inline>
</template>

<script>
    export default {
        data() {
            return {
                form: {},
            }
        },
        methods: {
            reset() {

            },
            doSearch() {
                this.$emit('search', this.form)
            }
        }
    }
</script>

