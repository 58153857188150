<template>
    <div class="page el-card is-always-shadow">
<!--        <el-card>-->
            <div class="header page-header">
                <slot name="title"></slot>
                <el-page-header @back="$router.go(-1)" :content="title"/>
            </div>
            <!--            <div class="search-title">-->
            <!--                <span>筛选查询</span>-->
            <!--            </div>-->
            <div class="page-search">
                <slot name="search"></slot>
            </div>
            <!--            <div class="listdata">-->
            <!--&lt;!&ndash;                <span>数据列表</span>&ndash;&gt;-->
            <!--                <slot name="but"></slot>-->
            <!--            </div>-->
            <div class="main-content">
                <div class="table-content">
                    <el-table :data="table.data"  v-loading="loading" width="100%" height="100%" size="small"
                              @selection-change="handleSelectionChange" @row-click="handleRowChange">
                        <slot></slot>
                    </el-table>
                </div>
            </div>
            <div class="page-footer">
                <el-pagination layout="total, prev, pager, next, sizes, jumper" :page-sizes="[10, 20, 30, 50, 100]"
                               :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                               @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"/>
            </div>
            <slot name="dialog"></slot>
            <slot name="download"></slot>
            <slot name="drawer"></slot>
<!--        </el-card>-->
    </div>
</template>


<script>
    export default {
        name: 'zx-admin-table',
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: '页面标题'
            },
            table: {
                default: {
                    search: [],
                    columns: [],
                    data: [],
                    total: 0,
                },
            },
        },
        methods: {
            handleCurrentChange(e) {
                this.table.page = e
                this.$emit('change', this.table)
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.table.page = 1
                this.$emit('change', this.table)
            },
            handleSelectionChange(val) {
                this.$emit('select', val)
            },
            handleRowChange(row, column) {
                this.$emit('row-click', row, column)
            },
        }
    }
</script>

<style scoped>
    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .page {
        height: calc(100% - 32px);
        display: flex;
        flex-direction: column;
        padding: 15px 0;
    }

    .header {
        padding: 0 15px 10px;
    }

    .header .btns {
        float: right;
    }

    .primary {
        background-color: #67c23a;
        border-color: #67c23a;
        color: white;
    }

    .info {
        background-color: #e4e4e4;
        border-color: #e4e4e4;
        color: #909399;
    }

    .el-tag--mini {
        width: 60%;
        height: 23px;
        padding: 0 5px;
        line-height: 20px;
        text-align: center;
        border-color: transparent;
    }

    .spans {
        color: #409eff;
        margin: 5px;
    }

    /*/deep/ .el-card__header {*/
    /*    background-color: rgba(243, 243, 243, 1);*/
    /*}*/

    .eltag {
        margin-left: 10px;
    }

    .eltag span {
        color: red;
    }

    /deep/ .el-tag--small {
        height: 30px;
        padding: inherit;
        line-height: 30px;
        width: 115px;
        text-align: center;
    }

    /deep/ .el-tag--plain {
        background-color: #fff;
        border-color: #cccccc;
        color: black;
    }

    .search-title {
        font-size: 13px;
        padding: 20px 0;
        margin-top: 10px;
        background-color: #909399;
    }

    .search-title span {
        margin-left: 20px;
    }

    /deep/ .el-card__body {
        padding: 15px 0;
    }

    .listdata {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        padding: 10px 0;
        /*background-color: rgba(243, 243, 243, 1);*/
    }

    .listdata span {
        margin-left: 20px;
    }

    /deep/ .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 10px;
    }

    .page-footer {
        margin-top: 20px;
        text-align: center
    }

    /deep/ .el-table tr {
        cursor: pointer;
    }

    .page-search {
        background-color: rgba(243, 243, 243, 1);
        /*padding: 6px 0;*/
    }
</style>
