<template>
    <el-container>
        <el-header class="header">
            <router-link to="/main/dashboard" class="logo">
                <img src="../../../assets/logo.png" alt="logo">
            </router-link>
            <el-button type="text" style="font-size:17px"
                       @click="handleCollapse"
                       icon="el-icon-menu"></el-button>
            <div style="color: #333333;font-size: 15px;font-weight: bold;padding-left: 20px">{{ title }}</div>
            <div class="head">
<!--                <el-row type="flex" class="row-bg" justify="center">-->
<!--                    <el-col :span="12" :offset="1"><div class="grid-content bg-purple">-->
<!--                        <el-input placeholder="检索应用名/App ID" v-model="form.keyword" class="input-with-select" style="width: 100%">-->
<!--                            <el-select slot="prepend" placeholder="请选择" style="padding: 0 8px" v-model="form.appTypt">-->
<!--                            </el-select>-->
<!--                            <span slot="prepend" style="color: #999999;padding: 0 5px">|</span>-->
<!--                            <el-select slot="prepend" placeholder="请选择" style="padding:0 8px" v-model="form.market">-->
<!--                            </el-select>-->
<!--                            <el-button slot="append" icon="el-icon-search" style="color: #fff"></el-button>-->
<!--                        </el-input>-->
<!--                    </div></el-col>-->
<!--                </el-row>-->

            </div>
            <div class="user">
                <el-dropdown trigger="click">
                    <span class="username">
                        {{ $store.getters.username }}
                        <div style="padding-left: 10px">
                            <img src="../../../assets/avatar.jpg" class="user-avatar"/>
                        </div>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <router-link class="link" to="/main/dashboard">
                            <el-dropdown-item>首页</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/main/password">
                            <el-dropdown-item>修改密码</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/logout">
                            <el-dropdown-item divided>退出登陆</el-dropdown-item>
                        </router-link>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-header>
        <el-container>
            <slot></slot>
        </el-container>
    </el-container>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: '中锐泓(上海)科技有限公司'
            }
        },
        data() {
            return {
                form: {}
            }
        },
        methods: {
            doLogout() {
                this.$router.push({path: '/'})
            },
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            },
            handleCollapse(){
                this.$emit('change')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header {
        color: #fff;
        display: flex;
        flex-direction: row;
        height: 60px;
        line-height: 60px;
        background: #fff;
        box-shadow: 0 2px 6px 1px rgba(33, 89, 229, .05);
        width: 100%;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 20;

        .logo {
            width: 190px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            text-decoration: none;

            img {
                height: 40px;
                border-radius: 5px;
                margin-right: 10px;
            }
        }

        .head {
            flex: 1;
        }
        .user {
            color: #ffffff;
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 180px;
            justify-content: flex-end;
            .user-avatar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: block;
                vertical-align: middle;
            }
        }
    }

    .link {
        text-decoration: none;
    }

    .header ::v-deep .el-select .el-input {
        width: 60px;
    }

    .header ::v-deep .input-with-select .el-input-group__prepend {
        background-color: #fff;
    }

    .header ::v-deep .el-input-group__append {
        background-color: #2259e5;
        border: inherit !important;
    }
    .username {
        /*color: #ffffff;*/
        cursor: pointer;
        vertical-align: middle;
        display: flex;
        align-items: center;
    }
</style>
