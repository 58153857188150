
const data = {
    menuItems: [
        {
            id: '100',
            label: '市场情报',
            path: '/main/dashboard',
            icon: 'el-icon-monitor',
        },
        {
            id: '200',
            label: 'APP市场',
            path: '/main/app',
            icon: 'el-icon-menu',
            children: [
                {
                    id: '200-1',
                    label: 'App Store市场',
                    path: '/main/app/ios',
                },
                {
                    id: '200-2',
                    label: '安卓市场',
                    path: '/main/app/android',
                }
            ]
        },
        {
            id: '300',
            label: '区域管理',
            path: '/main/company/index',
            icon: 'el-icon-office-building',
        },
        {
            id: '400',
            label: '任务管理',
            path: '/main/task/index',
            icon: 'el-icon-notebook-1',
        },
        {
            id: '500',
            label: '文件管理',
            path: '/main/file/index',
            icon: 'el-icon-notebook-2',
        },
        {
            id: '600',
            label: '字典管理',
            path: '/main/dict',
            icon: 'el-icon-document',
            children: [
                {
                    id: '500-1',
                    label: '字典类型',
                    path: '/main/dict/type',
                },
                {
                    id: '500-2',
                    label: '字典管理',
                    path: '/main/dict/list',
                }
            ]

        },
        {
            id: '700',
            label: '系统设置',
            path: '/main/settings',
            icon: 'el-icon-setting',
            children: [
                {
                    id: '700-1',
                    label: '用户管理',
                    path: '/main/settings/user',
                },
                {
                    id: '700-2',
                    label: '角色管理',
                    path: '/main/settings/role',
                },
                {
                    id: '700-3',
                    label: '公司名称中英',
                    path: '/main/settings/cnen',
                },
            ]
        },

    ]
}

export default data
