<template>
    <div class="page el-card is-always-shadow">
        <div slot="header" class="header page-header">
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-download" circle @click="doDownLoad" ></el-button>
            </el-row>
            <el-page-header @back="$router.go(-1)"  :content="$route.params.company_name"/>
        </div>
        <div class="title">
            <img src="../../../assets/company.svg">
            <div class="title-text">
                <div class="p-title"> {{$route.params.company_name}}</div>
            </div>
        </div>
        <div class="app-info-card dd-flex">
            <zx-market-card :form="item" v-for="(item,index) in marketList" :key="index"></zx-market-card>
        </div>
        <div class="listdata">
            <span>应用列表</span>
        </div>
        <div style="display: flex;padding: 20px;align-items: center">
            <div style="padding-right: 20px">商店</div>
            <el-radio-group v-model="market_id" size="small" @change="getData">
                <el-radio-button :label="item.market_id" v-for="(item,index) in marketList" :key="index">{{item.name}}
                </el-radio-button>
            </el-radio-group>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" v-loading="loading" stripe width="100%" height="100%" size="small"
                          @row-click="handleRowChange">
                    <el-table-column type="index"
                                     label="#"
                                     header-align="center"
                                     width="120"
                                     align="center">
                        <template slot-scope="scope">
                            <span>{{ (table.page - 1) * table.pageSize + scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     v-for="(v, idx) in table.columns"
                                     :prop="v.field"
                                     :label="v.title"
                                     :width="v.width"
                                     :formatter="v.formatter"
                                     :key="idx">
                        <template slot-scope="scope">
                            <div v-if="v.field !='online'">
                                <span v-if="v.field !='app_name'">{{ scope.row[v.field]}}</span>
                                <div v-else class="appicon">
                                    <div>
                                        <el-image :src="scope.row.icon" class="img">
                                            <div slot="error" class="image-slot">
                                                <img src="../../../assets/app.png" class="img">
                                            </div>
                                        </el-image>
                                    </div>
                                    <div style="padding-left: 10px;color: #4c91f6">
                                        <div>{{ scope.row.app_name}}</div>
                                        <div>{{ scope.row.developer}}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <el-tag type="info" v-if="!scope.row.online" size="small">下架</el-tag>
                                <el-tag type="success" v-else size="small">在架</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange"
                           @size-change="handlePageSizeChange"/>
        </div>
        <!--        </el-card>-->
    </div>
</template>

<script>
    import data from './data'

    export default {
        name: 'company',
        path: ['/main/company/app', '/main/company/app/:company_name'],
        data() {
            return {
                market_id: "",
                loading: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                form: {
                    name: ""
                },
                search: {},
                marketList: []
            }
        },
        methods: {
            handleRowChange(row) {
                if (row.market_id != '0') {
                    this.$router.push({
                        path: `/main/company/android/app/info/${row.company_name}/${row.pk}`,
                    })
                } else {
                    this.$router.push({
                        path: `/main/company/ios/app/info/${row.market_id}/${row.pk}`,
                    })
                }
            },
            doDownLoad(){
                this.$http.get(`/company/reportapp/${this.$route.params.company_name}/`, {
                    responseType: 'arraybuffer',
                    xsrfHeaderName: 'Authorization',
                }).then(res => {
                    let url = window.URL.createObjectURL(new Blob([res.data]))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    let fliename = res.headers['content-disposition']
                    link.setAttribute('download', fliename)
                    document.body.appendChild(link)
                    link.click()
                }).catch(err => {
                    console.log(err);
                });
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleClose() {
                this.dialogVisible = false
            },
            async handleUpdate(row) {
                this.form = row
                this.dialogVisible = true
            },
            async getMarket() {
                // this.loading = true;
                this.table.data = [];
                this.table.total = 0;
                this.marketList = [];
                this.market_id = "";
                try {
                    this.search.company_name = this.$route.params.company_name
                    const resp = await this.$http.get(`/app/market/?company_name=${this.search.company_name}`)
                    if (resp.status === 200) {
                        this.marketList = resp.data.data
                        if (resp.data.data.length) {
                            this.market_id = resp.data.data[0].market_id
                            this.getData()
                        }
                        // this.loading = false
                    } else {
                        // this.loading = false
                        this.$message.error(resp.data.message)
                    }
                } catch (e) {
                    // this.loading = false
                    this.$message.error(e.message)
                }
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                try {
                    if (this.search.daterange && this.search.daterange.length) {
                        this.search.start = this.search.daterange[0] || ''
                        this.search.end = this.search.daterange[1] || ''
                    }
                    this.search.company_name = this.$route.params.company_name
                    this.search.market_id = this.market_id
                    const resp = await this.$http.get(`/app?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    if (resp.status === 200) {
                        this.table.data = resp.data.data
                        this.table.total = resp.data.total
                        this.loading = false
                    } else {
                        this.$message.error(resp.data.message)
                        this.loading = false
                    }
                } catch (e) {
                    this.$message.error(e.message)
                    this.loading = false
                }
            },
        },
        mounted() {
            this.getMarket()
        }
    }
</script>
<style lang="scss" scoped>
    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .page {
        height: calc(100% - 32px);
        display: flex;
        flex-direction: column;
        padding: 15px 0;
    }
    .primary {
        background-color: #67c23a;
        border-color: #67c23a;
        color: white;
    }

    .info {
        background-color: #e4e4e4;
        border-color: #e4e4e4;
        color: #909399;
    }

    .el-tag--mini {
        width: 60%;
        height: 23px;
        padding: 0 5px;
        line-height: 20px;
        text-align: center;
        border-color: transparent;
    }

    .spans {
        color: #409eff;
        margin: 5px;
    }

    .eltag {
        margin-left: 10px;
    }

    .eltag span {
        color: red;
    }

    ::v-deep .el-tag--small {
        height: 30px;
        padding: inherit;
        line-height: 30px;
        width: 115px;
        text-align: center;
    }

    ::v-deep .el-tag--plain {
        background-color: #fff;
        border-color: #cccccc;
        color: black;
    }

    .search-title {
        font-size: 13px;
        padding: 20px 0;
        margin-top: 10px;
        background-color: rgba(243, 243, 243, 1);
    }

    .search-title span {
        margin-left: 20px;
    }

    ::v-deep .el-card__body {
        padding: 20px 0;
    }

    .listdata {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        padding: 10px 0;
        background-color: rgba(243, 243, 243, 1);
    }

    .listdata span {
        margin-left: 20px;
    }

    ::v-deep .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 10px;
    }

    .page-footer {
        margin-top: 20px;
        text-align: center
    }

    .title {
        display: flex;
        margin: 0 20px;
        margin-bottom: 20px;
        position: relative;

        img {
            border: 1px solid #eee;
            border-radius: 12px;
            border-radius: 24%;
            display: block;
            height: 70px;
            width: 70px;
            margin-right: 15px;
        }

        .title-text {
            display: inline-flex;
            flex-direction: column;
            justify-content: space-around;
        }

        .p-title {
            color: #222;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .app-info-card {
        transition: all .5s;
        width: 100%;
        height: 100px;
        background: #fbfbfb;
        /*padding: 0 23px;*/
        position: relative;
    }

    .dd-flex {
        display: flex;
    }

    .appicon {
        .img {
            width: 44px;
            height: 44px;
            border-radius: 8px;
        }

        display: flex;
        align-items: center;
    }

    ::v-deep .el-table tr {
        cursor: pointer;
    }
    .header {
        padding: 0 15px 10px;
        border-bottom: 1px solid #EBEEF5;
        margin-bottom: 10px;
    }

    ::v-deep .el-tag--small {
        width: 65px !important;
    }
</style>
