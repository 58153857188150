<template>
    <zx-admin-table title="字典列表" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:title>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible"/>
        </template>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" width="140" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button @click="handleUpdate(scope.row)"
                           size="mini"
                           type="warning" circle
                           icon="el-icon-edit">
                </el-button>
                <el-button @click="handleDel(scope.row)"
                           size="mini"
                           type="danger" circle
                           icon="el-icon-delete">
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogVisible=false" @update="getData"></data-form>
            <el-dialog
                    title="删除"
                    :visible.sync="delmodel"
                    width="30%"
            >
                <span style="text-align: center;">您确认要删除此数据？</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="delmodel = false" size="mini">取 消</el-button>
                  <el-button type="primary" @click="delSubmit" size="mini">确 定</el-button>
                </span>
            </el-dialog>
        </template>
    </zx-admin-table>
</template>
<script>
    import data from "./data"
    import SearchForm from './widgets/searchForm'
    import DataForm from './widgets/dataForm'

    export default {
        name: 'dict-list',
        path: '/main/dict/list',
        components: {
            SearchForm,
            DataForm
        },
        data() {
            return {
                loading: false,
                formData: [],
                form: {},
                delmodel: false,
                dialogVisible: false,
                searchVisible: true,
                table: data.table,
                search: {}
            }
        },
        methods: {
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            async getData() {
                this.loading = true
                try {
                    var start = ''
                    var end = ''
                    if (this.search.daterange && this.search.daterange.length) {
                        start = this.search.daterange[0] || ''
                        end = this.search.daterange[1] || ''
                    }
                    this.search.start = start;
                    this.search.end = end;
                    const resp = await this.$http.get(`/system/dictData/?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    if (resp.data.code !== 1) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.table.data = resp.data.data
                        this.table.total = resp.data.data.total
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            async handleDel(row) {
                this.delId = row.pk
                this.delmodel = true
            },
            async delSubmit() {
                try {
                    const resp = await this.$http.delete(`/system/dictData/${this.delId}`)
                    if (resp.data.code == 1) {
                        this.$message.success("提交成功!")
                        this.delmodel = false
                    }
                    else
                        this.$message.error(resp.data.message)
                    this.getData()

                } catch (e) {
                    this.$message.error(e.message)
                }

            },
            handleUpdate(row) {
                var temp = JSON.stringify(row)
                this.form = JSON.parse(temp)
                this.dialogVisible = true
            },
            handleAdd() {
                this.form = {}
                this.dialogVisible = true
            },
            handleCurrentChange(val) {
                this.table.page = val
                this.getData()
            },
            handleSizeChange(val) {
                this.table.pageSize = val
                this.getData()
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    /deep/ .el-table__empty-text {
        min-height: 400px;
        line-height: 400px;
    }
</style>
