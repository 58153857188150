<template>
    <zx-admin-table title="角色管理" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:title>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-plus" type="warning" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" />
        </template>
        <el-table-column type="index"
                         label="#"
                         header-align="center"
                         width="120"
                         align="center">
            <template slot-scope="scope">
                <span>{{ (table.page - 1) * table.pageSize + scope.$index + 1 }}</span>
            </template>
        </el-table-column>
        <el-table-column  show-overflow-tooltip
                          v-for="(v, idx) in table.columns"
                          :prop="v.field"
                          :label="v.title"
                          :width="v.width"
                          :formatter="v.formatter"
                          :key="idx">
        </el-table-column>
        <el-table-column align="center" label="操作" width="260">
            <template slot-scope="scope">
                <el-button @click="handleUpdate(scope.row)"
                           size="mini"
                           type="warning" circle
                           icon="el-icon-edit">
                </el-button>
                <el-button @click="handleDel(scope.row)"
                           size="mini"
                           type="danger" circle
                           icon="el-icon-delete">
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <el-dialog
                    title="添加/修改角色"
                    :visible.sync="model1"
                    width="50%"
            >
                <el-form :model="form" :rules="rules" ref="form" label-width="120px" style="padding-left:10%;">
                    <el-form-item label="角色名称" prop="name">
                        <el-input v-model="form.name" style="width:80%"></el-input>
                    </el-form-item>
                    <el-form-item label="角色描述">
                        <el-input v-model="form.remarks" type="textarea" style="width:80%" :rows="4" placeholder="请输入">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="菜单分配：">
                        <el-tree
                                :data="menusList"
                                :show-checkbox="true"
                                node-key="id"
                                ref="tree"
                                highlight-current
                                default-expand-all
                                :default-checked-keys="menuList"
                                :props="defaultProps">
                        </el-tree>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                <el-button @click="model1 = false">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
            </el-dialog>
            <el-dialog
                    title="删除"
                    :visible.sync="dialogVisible"
                    width="30%"
            >
                <span style="text-align: center;">您确定删除数据吗？</span>
                <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="delSubmit">确 定</el-button>
            </span>
            </el-dialog>
        </template>
    </zx-admin-table>
</template>

<script>
    import SearchForm from './widgets/searchForm'
    // import DataForm from './widgets/dataForm'
    import menu from "@/data/menu"
    import data from './data'
    export default {
        name: 'role',
        path: '/main/settings/role',
        components: {
            SearchForm,
            // DataForm,
        },
        data() {
            return {
                model1: false,
                loading: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                menusList: [],
                menuList: [],
                permissionList: [],
                defaultProps: {
                    children: 'children',
                    // eslint-disable-next-line no-unused-vars
                    label: (data, node) => {
                        return data.label
                    },
                },
                form: {
                    name:"",
                    remarks:""
                },
                treeList:[],
                search: {},
                rules: {
                    name: [
                        {required: true, message: '请选输入', trigger: 'blur'},
                    ]
                }
            };
        },
        methods: {
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleAdd() {
                this.form = {
                    name:"",
                    remarks:"",
                }
                this.menuList = []
                this.model1 = true
            },
            handleDel(row) {
                this.delpk = row.pk
                this.dialogVisible = true
            },
            handleUpdate(row) {
                this.form = {
                    pk:row.pk,
                    name:row.name,
                    remarks:row.remarks
                }
                this.model1 = true
                let menuList = []
                if (row.menuIds) {
                    menuList = row.menuIds.split(",")
                    this.menusList.forEach(e => {
                        // 删掉顶级目录，要不这个tree会都选上
                        if (e.children) {
                            menuList.remove(e.id)
                        }
                    })
                }
                if (this.$refs['tree']) {
                    this.$refs['tree'].setCheckedKeys(menuList);
                } else {
                    setTimeout(() => {
                        this.$refs['tree'].setCheckedKeys(menuList);
                    }, 200)
                }
            },
            delSubmit() {
                this.$http.delete(`/auth/role/${this.delpk}/`)
                    .then(resp => {
                        if (resp.data.code == 1) {
                            this.$message({
                                message: '提交成功',
                                type: 'success'
                            })
                            this.dialogVisible = false
                            this.getData()
                        } else {
                            this.$message({
                                message: resp.data.msg,
                                type: 'error'
                            })
                        }
                    })
            },
            addSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let menuIds = []
                        var dataList = this.$refs.tree.getCheckedNodes();
                        let currentNode = this.$refs.tree.getHalfCheckedNodes()
                        dataList.forEach(e => {
                            menuIds.push(e.id)
                        })
                        currentNode.forEach(e => {
                            menuIds.push(e.id)
                        })
                        this.form.menuIds = menuIds.join()
                        var method = this[`form`]['pk'] !== undefined ? 'put' : 'post'
                        this.$http[method]('/auth/role/', this.form)
                            .then(resp => {
                                if (resp.data.code == 1) {
                                    this.$message({
                                        message: '提交成功',
                                        type: 'success'
                                    })
                                    this.model1 = false
                                    this.getData()
                                } else {
                                    this.$message({
                                        message: resp.data.msg,
                                        type: 'error'
                                    })
                                }
                            })
                    }
                })
            },
            getData() {
                var start = ''
                var end = ''
                if (this.search.daterange && this.search.daterange.length) {
                    start = this.search.daterange[0] || ''
                    end = this.search.daterange[1] || ''
                }
                this.loading = true
                this.$http.get(`/auth/role/?page=${this.table.page}&pageSize=${this.table.pageSize}&start=${start}&end=${end}`)
                    .then(resp => {
                        this.table.data = resp.data.data
                        this.table.total = resp.data.total
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
            },
            getTree() {
                // this.$http.get('/menu')
                //     .then(resp=>{
                this.menusList =menu.menuItems

                    // }).catch(err=>{
                    // this.$message.error('获取菜单失败'+err.message)
                // })
                // this.permissionList = permission
            },
        },
        mounted() {
            this.getData();
            this.getTree();
        }
    }
</script>
