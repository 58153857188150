const data = {
    table: {
        columns: [
            {title: '任务ID', field: 'name', width: ''},
            {title: '任务状态', field: 'status', width: ''},
            {title: '创建时间', field: 'created', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
