<template>
    <zx-admin-table title="文件列表" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:title>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" />
        </template>
        <el-table-column type="index"
                         label="#"
                         header-align="center"
                         width="120"
                         align="center">
            <template slot-scope="scope">
                <span>{{ (table.page - 1) * table.pageSize + scope.$index + 1 }}</span>
            </template>
        </el-table-column>
        <el-table-column  show-overflow-tooltip
                          v-for="(v, idx) in table.columns"
                          :prop="v.field"
                          :label="v.title"
                          :width="v.width"
                          :formatter="v.formatter"
                          :key="idx">
            <template slot-scope="scope">
                <span v-if="v.field !='status'">{{ scope.row[v.field]}}</span>
                <div v-else>
                    <el-tag type="success" v-if="scope.row[v.field]=='1'" size="small">已完成</el-tag>
                    <el-tag type="warning" v-else-if="scope.row[v.field]=='0'" size="small">进行中</el-tag>
                    <el-tag type="danger" v-else size="small">失败</el-tag>
                </div>
            </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
            <template slot-scope="scope">
                <el-button @click="handleDowload(scope.row)"
                           size="mini"
                           type="primary" circle plain
                           v-if="scope.row.status=='1'"
                           icon="el-icon-download">
                </el-button>
            </template>
        </el-table-column>
    </zx-admin-table>
</template>

<script>
    import SearchForm from './widgets/searchForm'
    import data from './data'
    export default {
        name: 'file',
        path: '/main/file/index',
        components: {
            SearchForm,
        },
        data() {
            return {
                model1: false,
                loading: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                form: {},
                search: {},
                pk: null
            }
        },
        methods: {
            handleDowload(row) {
                this.$http.get(`/company/download/${row.pk}/`, {
                    responseType: 'arraybuffer',
                    xsrfHeaderName: 'Authorization',
                }).then(res => {
                    let url = window.URL.createObjectURL(new Blob([res.data]))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', row.fileName)

                    document.body.appendChild(link)
                    link.click()
                }).catch(err => {
                    console.log(err);
                });
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleClose() {
                this.dialogVisible = false
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                try {
                    var start = ''
                    var end = ''
                    if (this.search.daterange && this.search.daterange.length) {
                        start = this.search.daterange[0] || ''
                        end = this.search.daterange[1] || ''
                    }
                    this.search.start = start;
                    this.search.end = end;
                    const resp = await this.$http.get(`/system/file/?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    if (resp.status === 200) {
                        this.table.data = resp.data.data
                        this.table.total = resp.data.total
                    } else {
                        this.$message.error(resp.data.message)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    ::v-deep .el-tag--small {
        width: 65px !important;
    }
</style>
