import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css' // 响应式
import http from "./http"
import './prototype'
import './echarts'
import '../components'

Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.$http = http
