<template>
    <div class="control-items dd-flex dd-flex-column dd-flex-center dd-align-center" :class="[type==1?'bg':'qt',type==2?'status':'']" @click="handelInfo">
        <div class=" num dd-text-center font-600" :style="type==3?'margin-bottom: 10px;padding-top:10px':''||type==1?'font-size: 24px;':''">
            <span v-if="type==1">{{title}}</span>
            <countTo :startVal='0' :endVal='count' :duration='3000' v-else></countTo>
        </div>
        <div v-if="type==2">
            <div class="desc-num dd-text-center font-500">
                <span>{{title}}</span>
            </div>
            <div style="display: flex;justify-content: center;margin-top: 10px">
                <div class="top-change triangle font-12" :class="zCount<0?'green':'red'">
                    <span v-if="zCount<0">较昨日下降{{Math.abs(zCount)}}</span>
                    <span v-else>较昨天上升{{zCount}}</span>
                </div>
            </div>
        </div>
        <div v-if="type==3" style="text-align: -webkit-center;">
            <div class="sum">{{title}}</div>
        </div>
        <div style="display: flex;justify-content: center;padding-top: 5px">
            <div v-if="type==1" style="text-align: -webkit-center;" class="apptype">
                <img src="../../assets/ios.png" class="appimg" v-if="appType=='ios'">
                <img src="../../assets/android.png" class="appimg" v-else>
                <el-select v-model="appType" @change="handelType" size="small">
                    <el-option label="安卓" value="android"></el-option>
                    <el-option label="IOS" value="ios"></el-option>
                </el-select>
            </div>
        </div>
        <div class="line" v-if="type!=1"></div>
    </div>
</template>

<script>
    import countTo from 'vue-count-to';
    export default {
        name: "zx-count-card",
        components: { countTo },
        props :{
            count:{
                default: 0
            },
            status:{
                default: "1"
            },
            zCount:{
                default:0
            },
            title:{
                default:"",
            },
            type:{
                default:2
            }
        },
        data() {
            return {
                appType: "android"
            }
        },
        methods: {
            handelType() {
                this.$emit('handelType', this.appType);
            },
            handelInfo(){
                this.$emit('handelInfo', this.status);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .control-items .num{
        width: 100%;
        height: 38px;
        font-size: 32px;
        color: #fff;
        line-height: 38px;
        margin-bottom: 7px;
    }

    .control-filter a{
        color: #fff;
    }
    .font-600 {
        font-family: PingFangSC-Semibold,PingFang SC,sans-serif;
        font-weight: 600;
    }
    .dd-text-center {
        text-align: center;
    }

    .control-items{
        width: 25%;
        /*height: 102px;*/
        position: relative;
    }
    .dd-flex-column {
        flex-direction: column;
    }
    .dd-align-center {
        /*align-items: center;*/
    }
    .dd-flex-center {
        justify-content: center;
    }
    .dd-flex {
        display: flex;
    }

    div {
        display: block;
    }
    .control-filter{
        width: 100%;
        height: 186px;
        color: #fff;
        overflow: hidden;
        border-radius: 4px;
    }
    .control-filter .control-data-num .control-items .desc-num {
        width: 100%;
        height: 20px;
        line-height: 20px;
        position: relative;
        margin-bottom: 6px;
    }

    .control-filter a[data-v-20814792] {
        color: #fff;
    }
    .font-500 {
        font-family: PingFangSC-Medium,PingFang SC,sans-serif;
        font-weight: 600;
    }
    .dd-text-center {
        text-align: center;
    }
    a, li {
        text-decoration: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-tap-highlight-color: transparent;
        outline: none;
        color: #111;
    }

    a:-webkit-any-link {
        color: -webkit-link;
        cursor: pointer;
        text-decoration: underline;
    }
    .line{
        content: "";
        width: 1px;
        height: 66px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: hsla(0,0%,100%,.2)
    }
    .sum{
        width: 104px;
        height: 32px;
        line-height: 30px;
        background: hsla(0,0%,100%,.12);
        border-radius: 18px 18px 18px 18px;
        border: 1px solid hsla(0,0%,100%,.5);
        margin-top: 25px;
    }
    .appimg{
        width: 1.2em;
        height: 1.2em;
        vertical-align: -0.15em;
        /*fill: currentColor;*/
        /*overflow: hidden;*/
        /*padding: 5px 15px;*/
        cursor: pointer;
        /*background-color: white;*/
        /*margin: 5px;*/
        /*border-radius: 2px;*/
    }
    .bg{
        background-image: url('../../assets/title.png');
        background-size: cover;
        background-position: center;
        background-repeat: repeat;
        height: 100%;
        /*width: 100%;*/
    }
    .qt{
        /*padding-top: 25px;*/
    }
    .apptype{
        width: 120px;
        height: 32px;
        border-radius: 18px 18px 18px 18px;
        opacity: 1;
        border: 1px solid hsla(0,0%,100%,.5);
        padding: 0 10px;
        display: flex;
        align-items:center;
        ::v-deep .el-input__inner {
            background-color: initial;
            background-image: none;
            border-radius: 1px;
            border: initial;
            box-sizing: border-box;
            color: #fff;
            display: inline-block;
            height: 30px;
            line-height: 30px;
            width: 100%;
        }
    }
    .status{
        cursor: pointer;
    }

    .top-change{
        padding: 5px 9px;
        border-radius: 4px;
        position: relative;
    }
    .green {
        background: #00b595;
    }
    .red {
        background: #e94a4a;
    }
    .font-12 {
        font-size: 12px;
    }
    .top-change:after {
        content: "";
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
    }
    .green:after {
        border: 10px solid transparent;
        border-bottom-color: #00b595;
    }
    .red:after {
        border: 10px solid transparent;
        border-bottom-color: #e94a4a;
    }
</style>

