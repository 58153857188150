<template>
    <v-chart autoresize :option="options" style="width: 100%;height: 100%;min-height: 260px"  v-loading="loading"></v-chart>
</template>

<script>

    export default {
        props :{
            sjData: {
                type: Object,
                default: function() {
                    return {}; // 返回一个空对象作为默认值
                }
            },
            title:{
                default:"应用"
            }
        },
        name: "lineChart",
        data() {
            return {
                loading: false,
                options: {
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: [],
                        x: 'center',
                        y: 'bottom',
                    },
                    grid: {
                        top: "3%",
                        left: '2%',
                        right: '2%',
                        bottom: '14%',
                        containLabel: true
                    },
                    xAxis: {
                        show: true,
                        type: 'category',
                        boundaryGap: false,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'solid'
                            }
                        },
                        data: []
                    },
                    yAxis: {
                        show: true,
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'solid'
                            }
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: 'line',
                            stack: 'Total',
                            data: [],
                            // markPoint: {
                            //     data: [
                            //         { type: 'max', name: 'Max' },
                            //     ]
                            // },
                            itemStyle: {
                                color: '#2259e5', // 设置柱状图颜色
                            },
                        },
                    ]
                }
            };
        },
        methods: {
            async getData() {
                this.loading = true
                var that = this
                setTimeout(() => {
                    var vArray = []
                    var dArray = []
                    for (var key in that.sjData){
                        vArray.push(that.sjData[key])
                        dArray.push(key)
                    }
                    that.options.series[0].data = vArray
                    that.options.series[0].name = that.title
                    that.options.xAxis.data = dArray
                    that.options.legend.data.push(that.title)
                    that.loading = false
                }, 500)
            }
        },
        watch: {
            sjData: {
                handler() {
                    this.getData();
                },
            }
        },
        mounted() {
            this.getData()
        }
    };
</script>

<style scoped>
    .chart {
        width: 100%;
        height: 400px;
    }
</style>
