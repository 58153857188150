import formatter from '@/components/formatter'
const data = {
    table: {
        columns: [
            {title: '文件名称', field: 'name', width: ''},
            {title: '上传文件', field: 'upload_file_phat', width: ''},
            {title: '推送文件', field: 'dowload_file_phat', width: ''},
            {title: '任务状态', field: 'status', width: ''},
            {title: '任务id', field: 'seq_id', width: ''},
            {title: '创建时间', field: 'created', width: ''},
            {title: '更新时间', field: 'updated', width: ''}

        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
